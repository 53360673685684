html,
body {
  height: 100%;
  margin: 0;
  background: #f0f0f0;
}
#root {
  height: 100%;
  & > div {
    height: 100%;
  }
}
.topPadding {
  padding-top: 2rem;
}
